import React, {useState, useEffect} from 'react';
import axios from "axios";

const client = axios.create({
  baseURL: "https://kesseke.server.belavantage.com/users" 
});

export default function Profile() {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [passwordC, setPasswordC] = useState('');
    const [connected, setConnected] = useState(false)
    const [role, setRole] = useState('9cf04f75-2bbb-4c05-b873-fb45d62bbfe1');
    const [register, setRegister] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (register) {
            axios.post('https://kesseke.server.belavantage.com/users', {
                "email": e.target.email.value,
                "password": e.target.password.value,
                "role": role
            })
            .then((response) => {
                console.log(response);
            });
            e.target.reset();
        }
        axios.post('https://kesseke.server.belavantage.com/auth/login', {
            "email": e.target.email.value,
            "password": e.target.password.value,
        })
        .then((response) => {
            console.log(response);
            if (response.statusText === 'OK') {
                setConnected(true)
            }
        });
        e.target.reset();
        
       
        
     };

     useEffect(() => {
        setUser('');
        setPassword('');
        setPasswordC('')
      
     }, [register, connected])
     

    // 9cf04f75-2bbb-4c05-b873-fb45d62bbfe1
  return (
    <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-12 col-md-8 col-lg-6 col-xl-5">
        <div className="card shadow-2-strong" >
          <div className="card-body p-5 text-center">

            <h3 className="mb-5">{!register ? 'Sign in' : 'Register'}</h3>

            {connected && 
                <div className='lead text-success alert alert-success'>You are now connected</div>
            }

            <form onSubmit={(e) => handleSubmit(e)} className={connected ? 'd-none' : ''}>

            

            <div className="form-outline mb-4">
              <input type="email" name="email" id="typeEmailX-2" className="form-control form-control-lg" placeholder="Email" onChange={(e) => setUser(e.target.value)} />  
            </div>

            <div className="form-outline mb-4">
              <input type="password" name="password" id="typePasswordX-2" className="form-control form-control-lg" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />  
            </div>

            {register && <div className="form-outline mb-4">
              <input type="password" name="passwordConfirmation" id="typePasswordX-3" className="form-control form-control-lg" placeholder="Password confirmation" onChange={(e) => setPasswordC(e.target.value)} />  
            </div>}

            
            <div className="form-check d-flex justify-content-start mb-4">
              <input className="form-check-input me-2" type="checkbox" value="" id="form1Example3" />
              <label className="form-check-label" htmlFor="form1Example3"> Remember password </label>
            </div>

            <button className="btn btn-primary btn-lg btn-block px-5 w-100" type="submit" disabled={(register && (password === passwordC)) || (!register && (password === ''))} >Login</button>

            <hr className="my-4" />

            <div className="d-grid gap-2">

                <button className="btn btn-lg btn-block btn-outline-primary mb-2 align-items-center d-flex justify-content-center"
                type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google me-3" viewBox="0 0 16 16">
                    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                </svg>
                Sign in with google</button>
                <button className="btn btn-lg btn-block btn-outline-primary d-flex align-items-center justify-content-center mb-2"
                type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook me-3" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                </svg>
                Sign in with facebook</button>

            </div>

            <hr className="my-4" />

            <p>{!register ? "You don't have an account?" : "You already have an account?"}</p>
            <button className="btn btn-primary btn-lg" onClick={() => setRegister(!register)}>{!register ? 'Register' : 'Sign In'}</button>

            </form>


          </div>


        </div>
      </div>
    </div>
  </div>
  )
}
