import React, { useEffect, useState, useRef } from 'react';
import { exerciseOptions, fetchData } from '../utils/fetchData';
import axios from 'axios';
import { Directus } from '@directus/sdk';
import useStore from '../store/store';


export default function Search() {
    const [search, setSearch] = useState('');

    const searchTerm = useStore((state) => state.searchTerm);
    const updateSearchTerm = useStore((state) => state.updateSearchTerm);

    const directus = new Directus('https://kesseke.server.belavantage.com');
    const Exercise = directus.items('exercises');

    const inputRef = useRef(null);

    const handleInput = (e) => {
      e.preventDefault();
      updateSearchTerm(e.target.search.value)
      inputRef.current.blur();
    };

    const handleReset = (e) => {
      e.preventDefault();
      updateSearchTerm('');
      inputRef.current.value = "";
      inputRef.current.blur();
    }

    const handleSearch = async () => {
        //if (search) {

          // axios.get(`https://directus2.server.netavantage.com/items/Exercise/`)
          // .then(res => {
          //   const exerciseData = res.data;
          //   console.log (exerciseData );
          // })

        //}
        // await Exercise.readByQuery({
        //   // By default API limits results to 100.
        //   // With -1, it will return all results, but it may lead to performance degradation
        //   // for large result sets.
        //   limit: -1,
        // }).then(res => {
        //   console.log (res.data);
        // });

        // await Exercise.readByQuery({
        //   filter: {
        //     'equipment': {
        //       '_eq': search,
        //     },
        //   },
        // }).then(res => {
        //   console.log (res.data);
        //   console.log('search Term:', search);
        // });

        await Exercise.readByQuery({
          search: search,
          //sort: "sort,-date_created",
        }).then(res => {
          console.log (res.data);
          console.log('search Term:', search);
        });
        
        

        
        
    }   
  return (
    <div className="container-fluid bg-secondary search-bar">
      <div className="py-4 mx-auto col-11 col-md-6">
      <form onSubmit={handleInput}>
        <div className="input-group input-group-lg ">
          <input type="text" className="form-control" name="search" ref={inputRef}  placeholder="Search by target, equipment, body part" aria-label="Recipient's username" aria-describedby="button-addon2" />
          {(searchTerm !== '') && 
            <button className="btn btn-light text-danger d-inline-flex align-items-center" type="reset" onClick={handleReset}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          }
          <button className="btn btn-primary d-flex align-items-center" type="submit" id="button-addon2" >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
            <span className="d-none d-sm-inline ms-2">Search</span> 
          </button>
        </div>
      </form>
      </div>
    </div>
    
  )
}
