import create from "zustand";

const useStore = create((set) => ({

    searchTerm: '',
    updateSearchTerm: (searchTerm) => set(() => ({ searchTerm: searchTerm })),
    
    targets: [],
    
    addTarget: (targetName) =>
        set((state) => ({ targets: [...state.targets, targetName] })),
     
    removeTarget: (targetName) =>
      set((state) => ({
          targets: state.targets.filter(target => targetName !== target),
    })),

    bodyParts: [],

    addBodyPart: (bodyPartName) =>
        set((state) => ({ bodyParts: [...state.bodyParts, bodyPartName] })),

    removeBodyPart: (bodyPartName) =>
      set((state) => ({
        bodyParts: state.bodyParts.filter(bodyPart => bodyPartName !== bodyPart),
    })),

    equipments: [],

    addEquipment: (equipmentName) =>
        set((state) => ({ equipments: [...state.equipments, equipmentName] })),

    removeEquipment: (equipmentName) =>
      set((state) => ({
        equipments: state.equipments.filter(equipment => equipmentName !== equipment),
    })),


    }));
    
export default useStore;