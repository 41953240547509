import React, {useState, useEffect} from 'react';
import axios from "axios";
import { useQuery } from "react-query";
import { Directus } from '@directus/sdk';
import useStore from '../store/store';
import shallow from 'zustand/shallow'
import FilterButton from './FilterButton';

export default function ByEquipment(props) {
    //const {target, setTarget} = props;

    const { isLoading, isError, data, error, refetch } = useQuery(["equipment"], () =>
      axios
          .get("https://kesseke.server.belavantage.com/items/equipmentList")
          .then((res) => res.data.data.jsonEntry)
      );
    
      if (isLoading) return "Loading...";
    
      if (error) return "An error has occurred: " + error.message;
    
      console.log('target data:', data)
  
  return (
    <div className="bg-white p-4 border border-top-0 mb-4"> 

    {data.map((item, i) => (
        <FilterButton  name={item} index={i}  key={i} type="equipment" />   
      ))}
    </div>
  )
}
