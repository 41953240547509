import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ByBodyParts from './ByBodyParts';
import ByEquipment from './ByEquipment';
import ByTarget from './ByTarget';

export default function FilterTabs(props) {
    const {bodyPart, setBodyPart, setTarget, target} = props;
  return (
    
        
        <Tabs
            defaultActiveKey="bodyPart"
            id="justify-tab-example"
            className="mb-0 filter-tabs"
            
            >
            <Tab eventKey="bodyPart" title="Body Parts">
                <ByBodyParts bodyPart={bodyPart} setBodyPart={setBodyPart}/>
            </Tab>
            <Tab eventKey="target" title="Target">
                <ByTarget target={target} setTarget={setTarget} />
            </Tab>
            <Tab eventKey="equipment" title="Equipment">
                <ByEquipment />
            </Tab>
        </Tabs>
   
  )
}
