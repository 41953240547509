import React, {useState, useEffect} from 'react';
import axios from "axios";
import { useQuery } from "react-query";
import useStore from '../store/store';
import shallow from 'zustand/shallow'
import { Directus } from '@directus/sdk';
import FilterButton from './FilterButton';

export default function ByBodyParts(props) {
      const {bodyPart, setBodyPart} = props;
      const [bodyPartsData, setBodyPartsData] = useState();
      const [loading, setLoading] = useState(false);


      const { isLoading, isError, data, error, refetch } = useQuery(["bodyParts"], () =>
      axios
          .get("https://kesseke.server.belavantage.com/items/bodyPart")
          .then((res) => res.data.data.jsonEntry)
      );
    
      if (isLoading) return "Loading...";
    
      if (error) return "An error has occurred: " + error.message; 


     
  return (
    <>

      <div className="bg-white p-3 mb-4 border border-top-0">
        {data.map((item, i) => (
          <FilterButton  name={item} index={i}  key={i} type="bodyPart"  />   
        ))}
      </div>

    </>
  )
}
