import React, {useState, useEffect, memo} from 'react';
import useStore from '../store/store';
import shallow from 'zustand/shallow'


const FilterButton = ({name, index, type}) => {
    const [selected, setSelected] = useState(false); 
    
    const targets = useStore((state) => state.targets, shallow);
    const addTarget = useStore((state) => state.addTarget, shallow);
    const removeTarget = useStore((state) => state.removeTarget, shallow);

    const bodyParts = useStore((state) => state.bodyParts, shallow);
    const addBodyPart = useStore((state) => state.addBodyPart, shallow);
    const removeBodyPart = useStore((state) => state.removeBodyPart, shallow);

    const equipments = useStore((state) => state.equipments, shallow);
    const addEquipment = useStore((state) => state.addEquipment, shallow);
    const removeEquipment = useStore((state) => state.removeEquipment, shallow);

    const handleClick = (name) => {
      if (type === "target") {
        
        if (selected) {
          setSelected(false);
          removeTarget(name);
        }
        else {
          setSelected(true);
          addTarget(name);
        }
        
      } else if (type === "bodyPart") {

        if (selected) {
          setSelected(false);
          removeBodyPart(name);
        }
        else {
          setSelected(true);
          addBodyPart(name);
        }

      } else if (type === "equipment") {

        if (selected) {
          setSelected(false);
          removeEquipment(name);
        }
        else {
          setSelected(true);
          addEquipment(name);
        }

      }
      
      
    };

    



  return (
    <>
      <button key={index} className={`btn btn-sm m-2 ${selected ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => handleClick(name)}  >
          <span className="text-uppercase mt-4">{name}</span>
      </button>
    </>
   
  )
}

export default FilterButton;
