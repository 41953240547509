import React from 'react'

export default function Programs() {
  return (
    <div className="container py-5">
        <h1>My Programs</h1>
        <div className="lead">Comming soon</div>
    </div>
  )
}
