import React from 'react'

export default function HeaderImage() {
  return (
    <div className="pt-5 header-image container-fluid d-flex align-items-end justify-content-center">
        <div className="row ">
          <div className="mx-auto col-12 col-md-10">
            <h1 className="text-center text-white">Transform Your Body,<br/>One Workout at a Time!</h1>
            <p className="px-5 py-4 text-center text-white lead">Welcome to 'Time for Workout' - the ultimate fitness companion! Transform your body with 1300+ illustrated exercises, conveniently categorized by target, body part, and equipment. Get ready to achieve your fitness goals, one workout at a time!</p>
          </div>
        </div> 
    </div>
  )
}
