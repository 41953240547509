//import logo from './logo.svg';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './App.scss';
import Content from './components/Content';
import Favorites from "./components/Favorites";
import Header from './components/Header';
import HeaderImage from './components/HeaderImage';
import Profile from "./components/Profile";
import Programs from "./components/Programs";

const queryClient = new QueryClient({});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
     <Router>
      <div className="App">
        <Header />
        <HeaderImage />
        <Switch>
          <Route path="/" exact>
            <Content /> 
          </Route>
          <Route path="/favorites">
            <Favorites /> 
          </Route>
          <Route path="/programs">
            <Programs /> 
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          
        </Switch>
        
      </div>
     </Router>
      
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>
  );
}

export default App;
