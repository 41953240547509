import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';

export default function ModalExercise(props) {
    const {exercise, show, handleClose} = props;
    const [fullscreen, setFullscreen] = useState(true);
    
  return (
    <>
        
        <Modal 
        {...props}
        //show={show} 
        //fullscreen={fullscreen} 
        size="xl">
            <Modal.Header >
                <div className="text-capitalize fw-bold d-flex w-100 align-items-center justify-content-between">
                    <span>{exercise.name} </span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16" onClick={handleClose}>
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="p-md-5">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <img src={`https://kesseke.server.belavantage.com/assets/${exercise.image}`} alt={exercise.name} className="img-fluid" />
                        </div>
                        <div className="col-md-6">
                            <h3 className="text-capitalize h2">{exercise.name}</h3>
                            <div className="d-flex justify-content-between d-sm-block">
                                <div className="py-2">
                                    <div className="text-muted small">Body Part</div>
                                    <span className="badge bg-primary mx-sm-1 text-uppercase">{exercise.bodyPart}</span>
                                </div>
                                <div className="py-2">
                                <div className="text-muted small">Target</div>
                                    <span className="badge bg-primary mx-sm-1 text-uppercase">{exercise.target}</span>
                                </div>
                                <div className="py-2">
                                    <div className="text-muted small">Equipment</div>
                                    <span className="badge bg-primary mx-sm-1 text-uppercase">{exercise.equipment}</span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <hr/>
                </div>
            </Modal.Body>
        </Modal>
    </>
  )
}