import React, {useState} from 'react';
import ExerciseImage from './ExerciseImage';
//import Modal from 'react-bootstrap/Modal';
import FavoriteButton from './FavoriteButton';
import ModalExercise from './ModalExercise';

const Exercise = ({exercise, index, id, favorite, deleteFavorite, addFavorite}) => {
    const [modalShow, setModalShow] = useState(false); 
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    
    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setModalShow(true);
    }
    const handleClose = () => setModalShow(false);

    return (
        <div className="col" >
            <div className="card h-100">
                <FavoriteButton id={id} index={index} favorite={favorite} deleteFavorite={deleteFavorite} addFavorite={addFavorite} />
                <ModalExercise 
                exercise={exercise} 
                show={modalShow}
                handleClose={handleClose}
                />
                <div className="card-body d-flex justify-content-between align-items-center flex-column">
                    { (exercise.image === null)
                    ?
                    <ExerciseImage src={exercise.gifUrl} title={exercise.name} id={exercise.id} image={exercise.image} />
                    :
                    <img src={`https://kesseke.server.belavantage.com/assets/${exercise.image}`} className="thumbnail" alt={exercise.name} loading="lazy" onClick={() => handleShow("lg-down")} />
                    }
                    
                    
                    

                    <h6 className="mt-4 text-center text-uppercase" onClick={() => handleShow("lg-down")}>{exercise.name}</h6>
                    <div className="mt-4">
                        <span className="mx-1 badge bg-primary text-uppercase">{exercise.bodyPart}</span>
                        <span className="mx-1 badge bg-primary text-uppercase">{exercise.target}</span>
                        <span className="mx-1 badge bg-primary text-uppercase">{exercise.equipment}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercise;
