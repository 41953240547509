import React, {useEffect} from 'react';
import axios from 'axios';
const { Directus } = require('@directus/sdk');

const client = axios.create({
    baseURL: "https://kesseke.server.belavantage.com/files/import" 
  });

export default function ExerciseImage({src, title, id, image}) {

    const directus = new Directus('https://kesseke.server.belavantage.com');
    const exercises = directus.items('exercises2');


    useEffect(() => {

      const addPosts = async () => {

        if (image === null) {
            axios.post('https://kesseke.server.belavantage.com/files/import', {
              "url": src,
              "data": {
                  "title": title
              }
          })
          .then((response) => {
              console.log('img :',response);
              console.log('filename:', response.data.data.filename_disk);
              exercises.updateOne(
                id,
                {
                  image: response.data.data.id
                }
                
              );
          });
        }
        
        
      };
      
      addPosts();
      
    }, [image]);
    

    



  return (
    <div>{(image !== null && image) ? 'uploaded' : 'not uploaded'}</div>
  )
}
