import React, {useEffect, useState} from 'react'
import ModalExercise from './ModalExercise'

export default function FavoriteCard({favorites, deleteFavorite, index, id}) {
    const [modalShow, setModalShow] = useState(false); 
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    
    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setModalShow(true);
    }
    const handleClose = () => setModalShow(false);

  return (
    <div className="card favorite-card mb-4">
        <div className="card-body">
            <div className="row">
                <div className="col-8 mx-auto col-sm-4"><img src={favorites.gifUrl} className="img-fluid" onClick={() => handleShow("lg-down")} /></div>
                <div className="col-12 col-sm-8 justify-content-center d-inline-flex flex-column">
                    <h3 className="text-capitalize cliquable" onClick={() => handleShow("lg-down")} >{favorites.name}</h3>
                    <hr/>
                    <div className="info">
                        <div className="d-inline-block me-4 fw-bolder text-uppercase"><small className="text-capitalize d-block text-muted fw-lighter">Body Part</small>{favorites.bodyPart}</div>
                        <div className="d-inline-block me-4 fw-bolder text-uppercase"><small className="text-capitalize d-block text-muted fw-lighter">Target</small>{favorites.target}</div>
                        <div className="d-inline-block me-4 fw-bolder text-uppercase"><small className="text-capitalize d-block text-muted fw-lighter">Equipment</small>{favorites.equipment}</div>
                    </div>
                    
                    <hr/>
                    <div className="pt-0 pt-sm-2 action-buttons d-inline-flex align-items-center">
                        <button className="btn btn-danger d-inline-flex align-items-center me-3 mb-2" onClick={() => deleteFavorite(index)} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="d-none d-sm-inline-block bi bi-x-circle-fill me-2" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                            Remove from Favorites
                        </button>
                        <button className="btn btn-success d-inline-flex align-items-center mb-2 me-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="d-none d-sm-inline-block bi bi-plus-circle-fill me-2" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                            </svg>
                            Add to Programs
                        </button>

                    </div>
                    
                </div>
            </div>  
        </div>   
        <ModalExercise
            show={modalShow}
            handleClose={handleClose}
            exercise={favorites}
        />                    
    </div>
  )
}
