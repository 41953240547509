import React, {useState, useEffect, memo} from 'react';
import axios from "axios";
import { useQuery } from "react-query";
import useStore from '../store/store';
import shallow from 'zustand/shallow'
import { Directus } from '@directus/sdk';
import FilterButton from './FilterButton';

const ByTarget = ({target, setTarget}) => {
    //const {target, setTarget} = props;

    const targets = useStore((state) => state.targets, shallow);
    //const addTargets = useStore((state) => state.addTargets);
    //const removeTarget = useStore((state) => state.removeTarget);
    
    
    

    const { isLoading, isError, data, error, refetch } = useQuery(["targets"], () =>
      axios
          .get("https://kesseke.server.belavantage.com/items/target")
          .then((res) => res.data.data.jsonEntry)
      );
    
      if (isLoading) return "Loading...";
    
      if (error) return "An error has occurred: " + error.message;  
  
  return (
    <div className="bg-white p-3 mb-4 border border-top-0"> 
        
      {data.map((item, i) => (
        <FilterButton  name={item} index={i}  key={i} type="target" />   
      ))}
      

    </div>
  )
}

export default ByTarget;
