import React, {useState} from 'react';
import Exercises from './Exercises';
import FilterTabs from './FilterTabs';
import Search from './Search';
import SelectedFilters from './SelectedFilters';
import ByBodyParts from './ByBodyParts';


const Content = () => {
    const [bodyPart, setBodyPart] = useState('');
    const [target, setTarget] = useState('');
    return (
        <>
            <Search />   
            <Exercises bodyPart={bodyPart} setBodyPart={setBodyPart} target={target} setTarget={setTarget} />
        </>
    );
}

export default Content;
