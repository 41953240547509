import React, {useState, useEffect} from 'react';
import { useQuery } from "react-query";
import FavoriteCard from './FavoriteCard';

export default function Favorites() {
    const [favoritesItems, setFavoritesItems] = useState(JSON.parse(localStorage.getItem('favorites')) ?? []); //useState(JSON.parse(localStorage.getItem('favorites')) ?? []);  //useState([]); //

    const fetchAllFavorites = async () => await (await fetch(`https://kesseke.server.belavantage.com/items/exercises?filter[id][_in]=${favoritesItems}&meta=filter_count`)).json();

    // https://kesseke.server.belavantage.com/items/exercises?filter={"id":{"_in":[1,2]}}&meta=filter_count

    const { data, error, status, refetch } = useQuery("favorites", fetchAllFavorites);

  function deleteFavorite (index) {
    let clone = [...favoritesItems]
    clone.splice(index, 1)
    setFavoritesItems(clone);
}

  // useEffect(() => {
  //       const data = localStorage.getItem('favorites');
  //       if (data !== []) setFavoritesItems(JSON.parse(data));
  //   }, []);

useEffect(() => {
    localStorage.setItem('favorites', JSON.stringify(favoritesItems));
    refetch();
}, [favoritesItems]);

  return (
    <div className="container py-5">

        {status === "error" && <div>{error.message}</div>}

        {status === "loading" && <div>Loading...</div>}

        

        {console.log('favoritesItems', `{https://kesseke.server.belavantage.com/items/exercises?filter[id][_in]=${favoritesItems}&meta=filter_count}`)}

        <div className="row">
            <div className="col-md-9 mx-auto">
                <h2 className="mb-4">My Favorites</h2>

                {status === "success" && data.data.map((favorites, i) => (

                    <FavoriteCard key={i} favorites={favorites} deleteFavorite={deleteFavorite} index={i} id={favorites.id} />

                ))}
                
                {(favoritesItems.length === 0) && <p className="lead">No favorites yet</p>} 
            </div>
        </div>
        
        

        
    </div>
  )
}
