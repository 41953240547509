import React, {useEffect, useState, useRef} from 'react';
import Exercise from './Exercise';
import useStore from '../store/store';
import shallow from 'zustand/shallow'

import ReactPaginate from 'react-paginate';
//import Pagination from 'react-bootstrap/Pagination'
import { Directus } from '@directus/sdk';
//import Filters from './Filters';
import FilterTabs from './FilterTabs';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

export default function Exercises({bodyPart, setBodyPart, setTarget, target}) {
    const [exercisesData, setExercisesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [loading, setLoading] = useState(false);
    const [toggleFilter, setToggleFilter] = useState(false);

    const [favorites, setFavorites] = useState(JSON.parse(window.localStorage.getItem('favorites')) ?? []);

    const directus = new Directus('https://kesseke.server.belavantage.com');
    const exercises = directus.items('exercises2');

    const searchTerm = useStore((state) => state.searchTerm);
    const targets = useStore((state) => state.targets, shallow);
    const bodyParts = useStore((state) => state.bodyParts, shallow);
    const equipments = useStore((state) => state.equipments, shallow);

    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)

    
    const addFavorite = (id) => {
        let items = JSON.parse(window.localStorage.getItem("favorites"));
        setFavorites([...items, id]);
    }

    const deleteFavorite = (id) => {
        setFavorites(favorites.filter(item => item !== id));
    }

    useEffect(() => {
      window.localStorage.setItem('favorites', JSON.stringify(favorites));
          
    }, [favorites]);

    useEffect(() => {

      if (bodyParts.length !== 0) {

        async function fetchData() {
          // You can await here
          await exercises.readByQuery({
            limit: itemsPerPage,
            filter: {
                bodyPart: { _in: bodyParts },
            },          
            page: currentPage,
            meta: "filter_count",
          }).then(res => {
            setExercisesData(res.data);
            setTotalItems(res.meta.filter_count)
            // Fetch items from another resources.
              const endOffset = itemOffset + itemsPerPage;
              setCurrentItems(exercisesData.slice(itemOffset, endOffset));
              setPageCount(Math.ceil(res.meta.filter_count / itemsPerPage));
          });
          setTarget('');
  
        }
        fetchData();
        scrollToRef(myRef)
        setLoading(true);

      } else if (targets.length !== 0) {

        async function fetchData() {
          // You can await here
          await exercises.readByQuery({
            limit: itemsPerPage,
            filter: {
                target: { _in: targets },
            },          
            page: currentPage,
            meta: "filter_count",
          }).then(res => {
            setExercisesData(res.data);
            setTotalItems(res.meta.filter_count)
            // Fetch items from another resources.
              const endOffset = itemOffset + itemsPerPage;
              setCurrentItems(exercisesData.slice(itemOffset, endOffset));
              setPageCount(Math.ceil(res.meta.filter_count / itemsPerPage));
          });
          setTarget('');
  
        }
        fetchData();
        scrollToRef(myRef)
        setLoading(true);

      } else if (equipments.length !== 0) {

        async function fetchData() {
          // You can await here
          await exercises.readByQuery({
            limit: itemsPerPage,
            filter: {
                equipment: { _in: equipments },
            },          
            page: currentPage,
            meta: "filter_count",
          }).then(res => {
            setExercisesData(res.data);
            setTotalItems(res.meta.filter_count)
            // Fetch items from another resources.
              const endOffset = itemOffset + itemsPerPage;
              setCurrentItems(exercisesData.slice(itemOffset, endOffset));
              setPageCount(Math.ceil(res.meta.filter_count / itemsPerPage));
          });
          setTarget('');
  
        }
        fetchData();
        scrollToRef(myRef)
        setLoading(true);
        
      }
      else if (searchTerm !== '') {

        async function fetchData() {
          // You can await here
          await exercises.readByQuery({
            search: searchTerm,
            limit: itemsPerPage,        
            page: currentPage,
            meta: "filter_count",
          }).then(res => {
            setExercisesData(res.data);
            setTotalItems(res.meta.filter_count)
            // Fetch items from another resources.
              const endOffset = itemOffset + itemsPerPage;
              setCurrentItems(exercisesData.slice(itemOffset, endOffset));
              setPageCount(Math.ceil(res.meta.filter_count / itemsPerPage));
          });
          setTarget('');
  
        }
        fetchData();
        scrollToRef(myRef)
        setLoading(true);

      } else {

        async function fetchData() {
          // You can await here
          await exercises.readByQuery({
            limit: itemsPerPage,        
            page: currentPage,
            meta: "filter_count",
          }).then(res => {
            setExercisesData(res.data);
            setTotalItems(res.meta.filter_count)
            // Fetch items from another resources.
              const endOffset = itemOffset + itemsPerPage;
              setCurrentItems(exercisesData.slice(itemOffset, endOffset));
              setPageCount(Math.ceil(res.meta.filter_count / itemsPerPage));
          });
          setTarget('');
  
        }
        fetchData();
        scrollToRef(myRef)
        setLoading(true);

      }


      // if (bodyParts.length !== 0) {

        

      // } else if (target !== '') {

      //   async function fetchData() {
      //     // You can await here
      //     await exercises.readByQuery({
      //       // By default API limits results to 100.
      //       // With -1, it will return all results, but it may lead to performance degradation
      //       // for large result sets.
      //       limit: itemsPerPage,
      //       //search: bodyPart,
      //       filter: {
      //         target: {
      //           _eq: target,
      //         },
      //       },
      //       page: currentPage,
      //       meta: "filter_count",
      //     }).then(res => {
      //       console.log ('target data res',res);
      //       setExercisesData(res.data);
      //       setTotalItems(res.meta.filter_count)
      //       // Fetch items from another resources.
      //         const endOffset = itemOffset + itemsPerPage;
      //         console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      //         setCurrentItems(exercisesData.slice(itemOffset, endOffset));
      //         setPageCount(Math.ceil(res.meta.filter_count / itemsPerPage));
      //     });
      //     setBodyPart('');

      //   }
        
      //   fetchData();
      //   scrollToRef(myRef)
      //   setLoading(true);
        
      // } 
      // else {
      //   async function fetchData() {
      //     // You can await here
      //     await exercises.readByQuery({
      //       // By default API limits results to 100.
      //       // With -1, it will return all results, but it may lead to performance degradation
      //       // for large result sets.
      //       limit: itemsPerPage,
      //       page: currentPage,
      //       meta: "total_count",
      //     }).then(res => {
      //       console.log (res);
      //       setExercisesData(res.data);
      //       setTotalItems(res.meta.total_count)
      //       // Fetch items from another resources.
      //         const endOffset = itemOffset + itemsPerPage;
      //         console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      //         setCurrentItems(exercisesData.slice(itemOffset, endOffset));
      //         setPageCount(Math.ceil(res.meta.total_count / itemsPerPage));
      //     });
      //     // ...
      //   }
      //   fetchData();
      //   scrollToRef(myRef);
      //   setLoading(true);
      // }
       
           
    }, [currentPage, itemOffset, itemsPerPage, bodyPart, target, setTarget, setBodyPart, bodyParts, targets,equipments, searchTerm])

    

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % totalItems;
        console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    };
    
  return (
    <div className="container py-5" ref={myRef}>
        <div className="d-flex align-items-center">
          <h2 className="mb-0 text-capitalize">{(bodyPart !== '') ? bodyPart : ((target !== '')? target : "All") } Exercises</h2>
          <div className="ms-auto">
            <button className="mb-1 btn btn-secondary text-end" onClick={() => setToggleFilter(!toggleFilter)} >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter me-2" viewBox="0 0 16 16">
              <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
            </svg>
            Filter by</button>
          </div>
        </div>
        
        <hr />
        <div className="border-0 accordion">
              <div className="border-0 accordion-item bg-light" >
                <div className={`accordion-collapse collapse ${toggleFilter && 'show'}`}>
                  <FilterTabs />
                </div>
              </div>
              
        </div>

        <p className="my-5 text-center">{totalItems} { (totalItems !== '') && 'Exercises'}</p>
        
        <div className="row row-cols-2 row-cols-md-3 g-4">
            {loading && exercisesData.map((exercise, index) => (
              <Exercise key={exercise.id} index={index} exercise={exercise} id={exercise.id} favorite={favorites.some(element => (element === exercise.id) ? true : false)} addFavorite={addFavorite} deleteFavorite={deleteFavorite} />
            ))}
        </div>

        <p className="my-5 text-center">{totalItems} { (totalItems !== '') && 'Exercises'}</p>
        
        <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination py-5 justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
        />
    </div>
  )
}
