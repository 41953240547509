import React from 'react';
import {Link} from 'react-router-dom';

export default function Header() {
  return (
    <nav className="bg-secondary navbar navbar-dark fixed-bottom">
        <div className="container-fluid">
           
              <ul className="nav w-100 d-flex justify-content-between px-sm-4">
                <li className="nav-item">
                  <Link className="navbar-brand d-flex align-items-center" to="/">
                    <span className="logo-bg d-inline-flex align-items-center justify-content-center me-md-2"><img src="/images/tfw-100-3.png" alt="Time For Workout" width="50" height="50" className="mx-3" /></span>
                    <span className="d-none d-md-inline-block">Time<span className="text-primary">For</span>Workout</span>
                  </Link>
                </li>
                <li className="nav-item d-md-flex ms-sm-auto">
                  <Link className="nav-link d-flex flex-column flex-sm-row align-items-center active px-sm-4" aria-current="page" to="/favorites">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill me-md-2" viewBox="0 0 16 16">
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg>
                    <span>My Favorites</span>
                  </Link>
                </li>
                <li className="nav-item d-md-flex">
                  <Link className="nav-link d-flex flex-column flex-sm-row align-items-center px-sm-4" to="programs">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check-fill me-md-2" viewBox="0 0 16 16">
                      <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z"/>
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
                    </svg>
                    <span>My Programs</span>
                  </Link>
                </li>
                <li className="nav-item d-md-flex">
                  <Link className="nav-link d-flex flex-column flex-sm-row align-items-center px-sm-4" to="profile">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-badge-fill me-md-2" viewBox="0 0 16 16">
                      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z"/>
                    </svg>
                    <span>My Profile</span>
                  </Link>
                </li>
              </ul>
            
        </div>
    </nav>
  )
}
